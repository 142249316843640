//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CallTrIndex, CallTrDelete, CallTrBinding, CallTrUnBinding, CallTrSynchronization } from '@/api/updateUserInfo/users';
import Head from '@/components/head/index';
import StaffSelect from '@/components/StaffSelect/StaffSelectone';
import TimeScreeningse from '@/components/timeScreeningse';
import nxDataTabs from '@/components/nx-data-tabs/nx-data-tabs';
export default {
  name: 'outbound',
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '外呼管理',
      titleEs: '',
      binding: '绑定',
      Ifoutb: '',
      loading: true,
      accountId: '',
      ClassSearch: '',
      dialogVisible: false,
      tableData: [],
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      staffResult: [],
      staffDisableArr: [],
      radio: false,
      accountNumberId: '',
      staffSelectVisible: false,
      form: {
        call_name: '',
        call_pass: ''
      },
      options: [{
        id: '0',
        label: '全部'
      }, {
        id: '1',
        label: '空闲'
      }, {
        id: '2',
        label: '正在使用'
      }],
      value: '0'
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.getList(this.query.page, this.query.pagesize);
    } else {}
  },
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse,
    nxDataTabs: nxDataTabs,
    StaffSelect: StaffSelect
  },
  methods: {
    getList: function getList(page, pagesize) {
      var _this = this;
      this.loading = true;
      CallTrIndex({
        status: this.value,
        keyword: this.ClassSearch,
        pagesize: String(pagesize),
        page: String(page)
      }).then(function (respomse) {
        _this.query.total = respomse.data.total;
        _this.tableData = respomse.data.data;
        _this.loading = false;
      });
    },
    // NewEditor(val, row) {
    //     //编辑弹框没有赋值
    //     this.Ifoutb = val;
    //     if (val == 1) {
    //         this.dialogVisible = true;
    //         this.form.call_name = '';
    //         this.form.call_pass = '';
    //         this.titleEs = '添加外呼账号';
    //     } else {
    //         this.dialogVisible = true;
    //         this.form.call_name = row.call_name;
    //         this.form.call_pass = row.call_pass;
    //         this.titleEs = '编辑外呼账号';
    //         this.accountId = String(row.id);
    //     }
    // },
    selectChanged: function selectChanged(value) {
      //状态
      this.value = value;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    ClassSearchInput: function ClassSearchInput() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    bindingEs: function bindingEs(row) {
      this.staffSelectVisible = true;
      this.radio = true;
      this.accountNumberId = row.id;
    },
    staffSelectConfirm: function staffSelectConfirm(resultNameArr, result) {
      var _this2 = this;
      //绑定账号
      CallTrBinding({
        id: String(this.accountNumberId),
        user_id: String(result),
        user_name: String(resultNameArr)
      }).then(function (res) {
        _this2.$notify({
          title: '成功',
          message: '绑定成功',
          type: 'success'
        });
        _this2.query.page = 1;
        _this2.currentPage4 = 1;
        _this2.getList(_this2.query.page, _this2.query.pagesize);
      }).catch(function () {});
    },
    Unbundling: function Unbundling(row) {
      var _this3 = this;
      this.$confirm('确认将' + row.cno + '和' + row.user_name + '解绑？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        CallTrUnBinding({
          id: String(row.id)
        }).then(function (res) {
          _this3.getList(_this3.query.page, 15);
          _this3.$notify({
            title: '成功',
            message: '解绑成功',
            type: 'success'
          });
        });
      }).catch(function () {});
    },
    handleSizeChange: function handleSizeChange(val) {
      this.getList(this.query.page, val);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.query.page = val;
      this.getList(val, this.query.pagesize);
    },
    handleClose: function handleClose(done) {
      //关闭弹窗
      this.$confirm('确认关闭？').then(function (_) {
        done();
      }).catch(function (_) {});
    },
    // dialogVisileft() {
    //     //新增编辑
    //     if (this.Ifoutb == 1) {
    //         let data = {
    //             call_name: this.form.call_name,
    //             call_pass: this.form.call_pass
    //         };
    //         CallAdd(data).then((res) => {
    //             this.dialogVisible = false;
    //             this.getList(1, 15);
    //             this.$notify({
    //                 title: '成功',
    //                 message: '添加成功',
    //                 type: 'success'
    //             });
    //         });
    //     } else {
    //         let data = {
    //             call_name: this.form.call_name,
    //             call_pass: this.form.call_pass,
    //             id: this.accountId
    //         };
    //         CallAdd(data).then((res) => {
    //             this.dialogVisible = false;
    //             this.getList(this.query.page, 15);
    //             this.$notify({
    //                 title: '成功',
    //                 message: '编辑成功',
    //                 type: 'success'
    //             });
    //         });
    //     }
    // },
    deleteOutbound: function deleteOutbound(row) {
      var _this4 = this;
      var val;
      if (row.status != 1) {
        val = '确认将' + row.cno + '和' + row.user_name + '解绑并删除坐席号？';
      } else {
        val = '确认删除' + row.cno + '坐席号？';
      }
      this.$confirm(val, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        CallTrDelete({
          id: row.id
        }).then(function (res) {
          _this4.getList(_this4.query.page, 15);
          _this4.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          });
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //同步
    Synchronization: function Synchronization() {
      var _this5 = this;
      this.loading = true;
      CallTrSynchronization().then(function (res) {
        _this5.loading = false;
        _this5.query.page = 1;
        _this5.getList(_this5.query.page, _this5.query.pagesize);
      });
    }
  }
};